import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";

// Assets

const CareersFirmwareEngineer = () => (
  <Layout currentPage="careers-listing">
    <div className="container">
      <Link to="/careers#alljobs">
        <p className="backbutton col-md-12">Back to Careers</p>
      </Link>
      <h1 className="jobtitle col-md-12">Director of Business Development</h1>
      <div className="jobdescription col-md-12">
        We have big dreams and we can’t do it alone. Uno’s success relies on
        establishing strong win-win partnerships with other businesses.
      </div>
      <div className="row col-md-12 justify-content-md-between">
        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You will:
          </div>
        </div>
        <div className="col-md-9">
          <ul className="aligned-left jobsection">
            <li>
              Be responsible for developing a partnership strategy and building
              a robust pipeline of prospective partners such as coffee shops,
              fast casual lunch establishments, gyms, juice shops, delis,
              convenience stores, etc.
            </li>
            <li>
              Work closely with a wide array of cross-functional stakeholders to
              ensure partnerships are executed flawlessly and deliver value to
              the business.{" "}
            </li>
            <li>
              Lead and coordinate internal resources for due diligence,
              including input on the legal, regulatory, product development, and
              go-to-market aspects of potential transactions.
            </li>
            <li>
              Develop and negotiate deal terms for partnership agreements.
            </li>
            <li>Build a team that can scale quickly.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">You are:</div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>Unbearably convincing and make it seem easy.</li>
            <li>
              Natural at nurturing meaningful connections with people and
              organizations.
            </li>
            <li>A mediator who can find strong common ground.</li>
            <li>Energized by being on the ground and making things happen.</li>
            <li>Passionate about sustainability.</li>
            <li>
              Observant of trends, up and coming brands, celebrities and media
              outlets.
            </li>
            <li>An effective communicator in writing and in-person.</li>
            <li>Self-starter with who can follow through.</li>
            <li>Someone who rarely takes no for an answer.</li>
            <li>Able to make people feel your ideas were their ideas.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You bring:
          </div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>BA or MBA.</li>
            <li>
              6+ years of business development and partnerships experience.{" "}
            </li>
            <li>
              Business development success within the SaaS, technology or retail
              sectors.
            </li>
            <li>
              A network of professionals who will make introductions and
              open doors.
            </li>
            <li>
              Passion and knowledge about platforms, with strong technical level
              understanding of APIs and platform development.
            </li>
            <li>
              4+ years experience managing a team, mentoring/coaching, and
              developing skills of individual contributors.
            </li>
            <li>
              Track record of managing a growing number of partners; ability to
              parallel process multiple projects.
            </li>
            <li>
              Excellent negotiation skills, with a proven track record of
              closing agreements on mutually favorable terms; strong aptitude
              working with legal contracts.
            </li>
            <li>
              A demonstrated ability to multi-task and to individually
              accomplish large amounts of work.
            </li>
            <li>
              A demonstrated track record of effectively working across
              departments, across functionality, and across organizations.
            </li>
            <li>
              Experience in working with senior management to align sales
              strategies and solutions, including ability to effectively
              communicate with a C-level audience.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You apply:
          </div>
        </div>
        <div className="col-md-9">
          <div className="aligned-left" style={{ paddingBottom: "100px" }}>
            By sending us an <a href="mailto:careers@introducing.uno">email</a>{" "}
            with your resume and a cover letter.
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default CareersFirmwareEngineer;
